<template>
  <div class="wrapper">
    <div v-if="isLoadingOptions">
      <ClassificationTaskOptionsPanelSkeleton />
    </div>
    <div v-else class="sticky-header">
      <div class="document-options-wrapper pt-4 pb-4">
        <div class="document-option-section-wrapper">
          <div class="col-5">
            <div>
              <SearchableDropdown
                :class="hasSupplierErrorFlag ? `supplier-dropdown-error` : ''"
                class="field-size supplier-dropdown"
                :custom-width="'550px'"
                :selected-item="selectedSupplierItem"
                :options="presentedSuppliers"
                :label="$t('document.documentsOverview.tenantDocumentsTable.header.supplierName')"
                :search-placeholder="$t('commons.searchWithDots')"
                mark-selected
                @change="updateSupplierId"
              />
            </div>
          </div>

          <div class="col-2">
            <div :class="hasTypeErrorFlag ? `error-border` : ''">
              <el-select
                :value="document.type"
                clearable
                filterable
                size="mini"
                class="field-size"
                :placeholder="$t('document.documentsOverview.classificationTasks.document.fields.docType')"
                @input="updateDocType"
              >
                <el-option
                  v-for="_type in documentsTypes"
                  :key="_type"
                  :value="_type"
                  :label="$t(`document.exports.schema.type.fullName.${_type}`)"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-2">
            <div style="position: relative">
              <el-input
                :value="document.documentNumber"
                :disabled="document.replicateOf ? true : false"
                class="field-size"
                type="text"
                :placeholder="$t('document.documentsOverview.classificationTasks.document.fields.docNumber')"
                @input="onChangeDocumentNumber"
              />
              <div>
                <small v-if="isDocumentNumberAlreadyExists" class="el-form-item__error error-position">
                  <DocumentAlreadyExist
                    :document-type="document.type"
                    :is-document-number-already-exists="isDocumentNumberAlreadyExists"
                    :is-replicate-of="!!document.replicateOf"
                    @on-show-document="showAlreadyExistDocument = true"
                    @on-toggle-replicate-of="() => $emit('on-toggle-replicate-of')"
                  />
                </small>
              </div>
            </div>
          </div>

          <div class="col-4 d-flex align-self-center">
            <label :style="'color:' + hasDeclarationColor">{{
              $t('document.documentsOverview.classificationTasks.document.hasDeclaration.title')
            }}</label>
            <div>
              <div :class="hasDeclarationErrorFlag ? `error-border` : ''">
                <el-radio-group
                  :value="String(hasDeclaration)"
                  :disabled="isHasDeclarationDisabled"
                  @input="onChangeHasDeclaration"
                >
                  <el-radio class="mx-2" label="true" size="large">
                    <span class="mx-1">
                      {{ $t('document.documentsOverview.classificationTasks.document.hasDeclaration.yes') }}
                    </span>
                  </el-radio>
                  <el-radio class="mx-2" label="false" size="large">
                    <span class="mx-1">
                      {{ $t('document.documentsOverview.classificationTasks.document.hasDeclaration.no') }}
                    </span></el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="document-option-section-wrapper">
          <div>
            <el-tooltip
              class="box-item fontSize"
              effect="dark"
              :content="$t('document.documentsOverview.classificationTasks.document.createdAt') + formattedCreatedAt"
              placement="top"
            >
              <div>
                <span class="col fontSize">{{ source }}</span>
                <DotIcon class="mx-2" />
                <span class="col fontSize">{{ username }}</span>
              </div>
            </el-tooltip>
          </div>
          <div>
            <el-tooltip
              v-if="document.replicateOf"
              class="box-item fontSize"
              effect="dark"
              :content="$t('document.validation.documentNumber.replicatedDocument')"
              placement="top"
            >
              <DocumentDuplicate class="duplicate-document-icon" />
            </el-tooltip>
          </div>
        </div>
      </div>
      <div v-if="!isLoadingTemplatesPanel" class="template-filter-wrapper">
        <div v-if="isSupplierAndDocTypeSelected" class="template-filter-container">
          <ClassificationTaskTemplateFilterQuestion
            v-for="{ paramField, paramPresentedField, answerValue } in visibleTemplateQuestions"
            :key="paramField"
            :param-field="paramField"
            :param-presented-field="paramPresentedField"
            :answer-value="answerValue"
            @question-answer="handleNewAnswer"
            @answer-remove="handleRemoveAnswer"
          />
        </div>
      </div>
    </div>
    <div v-if="isLoadingTemplatesPanel">
      <ClassificationTaskTemplatesSkeleton />
    </div>
    <div v-else>
      <div v-if="!isSupplierAndDocTypeSelected">
        <div class="waiting-for-supplier-and-doc-type">
          {{ $t('document.documentsOverview.classificationTasks.templates.waitingForSupplierAndDocType') }}
        </div>
      </div>
      <div v-else class="templates-wrapper">
        <div v-for="template in templatesCard" :key="template.id" class="template-card-item">
          <TemplateCardNew
            ref="templateCardRefs"
            :general-tags="template.generalTags"
            :item-tags="template.itemsTags"
            :reference-tags="template.referenceTags"
            :previewed-path="template.previewedPath"
            :checked="template.checked"
            :template-id="template.templateId"
            :template-name="template.templateName"
            @selected="onChangeTemplate"
            @toggleStructureParams="onStructureParamToggle"
          />
        </div>
        <div class="template-card-item">
          <NonTemplateCardNew
            :checked="!selectedTemplate"
            :has-locale-error-flag="hasLocaleErrorFlag"
            @selected="onChangeTemplate"
            @locale-changed="onLocaleChanged"
            @is-manual-changed="onIsManualChanged"
          />
        </div>
      </div>
    </div>
    <DocumentModal
      v-if="showAlreadyExistDocument"
      visible
      :document-id="alreadyExistsDocumentWithDocumentNumber?.id"
      @close="showAlreadyExistDocument = false"
    />
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from 'vue';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { omit, isNil } from 'ramda';
import { options } from '@/locale/dateConfig';
import typography from '@/stylesheets/scss/typography.module.scss';
import ClassificationTaskOptionsPanelSkeleton from './ClassificationTaskOptionsPanelSkeleton';
import ClassificationTaskTemplatesSkeleton from './ClassificationTaskTemplatesSkeleton';
import NonTemplateCardNew from './nonTemplateCardNew.vue';
import TemplateCardNew from './templateCardNew.vue';
import ClassificationTaskTemplateFilterQuestion from './ClassificationTaskTemplateFilterQuestion';
import { DotIcon, DocumentDuplicate } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import DocumentAlreadyExist from './DocumentAlreadyExist';
import { SearchableDropdown } from './table-filters';

export default {
  components: {
    ClassificationTaskOptionsPanelSkeleton,
    ClassificationTaskTemplatesSkeleton,
    NonTemplateCardNew,
    TemplateCardNew,
    DotIcon,
    DocumentDuplicate,
    DocumentModal,
    DocumentAlreadyExist,
    SearchableDropdown,
    ClassificationTaskTemplateFilterQuestion,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    suppliers: {
      type: Array,
      default() {
        return [];
      },
    },
    documentsTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    hasDeclarationErrorFlag: {
      type: Boolean,
      default: false,
    },
    hasSupplierErrorFlag: {
      type: Boolean,
      default: false,
    },
    hasTypeErrorFlag: {
      type: Boolean,
      default: false,
    },
    alreadyExistsDocumentWithDocumentNumber: {
      type: Object,
      default() {
        return {};
      },
    },
    isSupplierAndDocTypeSelected: {
      type: Boolean,
    },
    isLoadingTemplatesPanel: {
      type: Boolean,
    },
    isLoadingOptions: {
      type: Boolean,
    },
    templates: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedTemplate: {
      type: String,
      required: false,
      default: null,
    },
    hasLocaleErrorFlag: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: [
    'on-change-template',
    'locale-changed',
    'is-manual-changed',
    'on-filter-template-question-presented',
    'on-filter-template-question-answered',
    'on-filter-template-question-answer-removed',
  ],
  setup(props, { emit }) {
    const templateCardRefs = ref([]);

    const handleScroll = () => {
      templateCardRefs.value.forEach((templateCardRef) => {
        if (templateCardRef) {
          templateCardRef.closeStructureParams();
        }
      });
    };

    const { $i18n } = getCurrentInstance().proxy;

    const getSupplierPresented = (supplier) =>
      `${supplier.name} - ${$i18n.t('commons.companyNumber')} ${supplier.number}`;

    const selectedSupplierItem = computed(() => {
      const selectedSupplier = props.suppliers.find((item) => item.id === props.document.supplierId) ?? null;
      if (isNil(selectedSupplier)) return;
      return { id: selectedSupplier?.id, name: getSupplierPresented(selectedSupplier) };
    });

    const presentedSuppliers = computed(() => {
      return props.suppliers.map((supplier) => {
        return {
          id: supplier.id,
          name: getSupplierPresented(supplier),
          number: supplier.number,
        };
      });
    });

    /**
     * Return map of tags to the count of time that they appear in the structureParams
     * @param {*} templates
     * @param {'general'|'items'|'references'} type
     * @returns  [{ [tagName]: count }]
     * @example [{taxAmount: 1}, {taxRate: 3} ]
     */
    const getTagsCount = (templates, type) => {
      return templates.reduce((acc, template) => {
        const { structureParams = {} } = template;

        const typeStructureParams = (type === 'general' ? structureParams : structureParams[type]) || {};

        for (const [tag, isExists] of Object.entries(typeStructureParams)) {
          if (isExists) {
            acc[tag] = (acc[tag] || 0) + 1;
          }
        }
        return acc;
      }, {});
    };

    const isUnique = (tagsCount, templatesCount) => {
      return templatesCount <= 1 ? false : tagsCount === 1;
    };

    const getGeneralTagsFromTemplate = (template, tagsCount, templatesCount) => {
      return Object.entries(omit(['__typename', 'references', 'items'], template.structureParams))
        .filter(([, isExists]) => isExists)
        .map(([key]) => ({ name: key, highlighted: isUnique(tagsCount[key], templatesCount) }));
    };

    const getItemsTagsFromTemplate = (template, tagsCount, templatesCount) => {
      return Object.entries(omit(['__typename'], template.structureParams?.items) || {})
        .filter(([, isExists]) => isExists)
        .map(([key]) => ({ name: key, highlighted: isUnique(tagsCount[key], templatesCount) }));
    };

    const getReferenceTagsFromTemplate = (template, tagsCount, templatesCount) => {
      return Object.entries(omit(['__typename'], template.structureParams?.references) || {})
        .filter(([, isExists]) => isExists)
        .map(([key]) => ({ name: key, highlighted: isUnique(tagsCount[key], templatesCount) }));
    };

    const templatesCard = computed(() => {
      const templates = filteredTemplates.value;

      const generalTagsCount = getTagsCount(templates, 'general');
      const itemsTagsCount = getTagsCount(templates, 'items');
      const referenceTagsCount = getTagsCount(templates, 'references');

      const templatesCard = [];
      for (const template of templates) {
        const generalTags = getGeneralTagsFromTemplate(template, generalTagsCount, templates.length).map((item) => ({
          ...item,
          name: $i18n.t(`document.exports.schema.fields.${item.name}`),
        }));

        const itemsTags = getItemsTagsFromTemplate(template, itemsTagsCount, templates.length).map((item) => ({
          ...item,
          name: $i18n.t(`document.exports.schema.itemsFields.${item.name}`),
        }));

        const referenceTags = getReferenceTagsFromTemplate(template, referenceTagsCount, templates.length).map(
          (item) => ({
            ...item,
            name: $i18n.t(`document.exports.schema.referencesFields.${item.name}`),
          })
        );

        const previewedPath = template?.document?.filePathUrl;
        const checked = props.selectedTemplate === template.id;
        const templateId = template.id;
        const templateName = template.name;
        templatesCard.push({
          referenceTags,
          generalTags,
          itemsTags,
          previewedPath,
          checked,
          templateId,
          templateName,
        });
      }

      return templatesCard;
    });

    const templateFilterAnswers = ref([]);

    watch([() => props.templates, () => props.task], () => (templateFilterAnswers.value = []));

    const templateFilterQuestions = computed(() => {
      const templates = filteredTemplates.value || [];
      const generalTagsCount = omit(['__typename', 'items', 'references'], getTagsCount(templates, 'general'));
      const itemsTagsCount = omit(['__typename'], getTagsCount(templates, 'items'));
      const referenceTagsCount = omit(['__typename'], getTagsCount(templates, 'references'));

      const parseParamsCount = (section, paramsCount) => {
        return Object.entries(paramsCount).map(([param, appearCount]) => ({ [`${section}.${param}`]: appearCount }));
      };

      const byWeight = (a, b) => {
        const [aValue] = Object.values(a);
        const [bValue] = Object.values(b);
        return aValue - bValue;
      };

      const getPresentedFieldName = (section, key) => {
        if (section === 'general') {
          return $i18n.t(`document.exports.schema.fields.${key}`);
        }
        if (section === 'items') {
          return `${$i18n.t(`document.exports.schema.fields.items`)} - ${$i18n.t(
            `document.exports.schema.itemsFields.${key}`
          )}`;
        }
        if (section === 'references') {
          return `${$i18n.t(`document.exports.schema.fields.references`)} - ${$i18n.t(
            `document.exports.schema.referencesFields.${key}`
          )}`;
        }
      };

      const filterAnswered = (question) => {
        const [field] = Object.keys(question);
        return !templateFilterAnswers.value.find((answer) => {
          return answer.paramField === field;
        });
      };

      const getQuestions = () => {
        const weightParams = [
          ...parseParamsCount('general', generalTagsCount),
          ...parseParamsCount('items', itemsTagsCount),
          ...parseParamsCount('references', referenceTagsCount),
        ]
          .filter(filterAnswered)
          .sort(byWeight);

        return weightParams.map((param) => {
          const [[field, weight]] = Object.entries(param);
          const [section, key] = field.split('.');
          const paramPresentedField = getPresentedFieldName(section, key);
          return {
            weight,
            paramField: field,
            paramPresentedField,
            answerValue: null, // true / false / null
          };
        });
      };

      return getQuestions();
    });

    const filteredTemplates = computed(() => {
      const templates = props.templates || [];
      if (templateFilterAnswers.value.length === 0) return templates;
      return templates.filter((template) => {
        let shouldKeepTemplate = true;
        templateFilterAnswers.value.forEach(({ paramField, answerValue }) => {
          const [section, param] = paramField.split('.');
          console.log(template.structureParams);
          const templateSpValue =
            section === 'general' ? template.structureParams[param] : template.structureParams?.[section]?.[param];
          if (!!templateSpValue !== answerValue) shouldKeepTemplate = false;
        });
        return shouldKeepTemplate;
      });
    });

    const visibleTemplateQuestions = computed(() => {
      let shouldStopAddMoreQuestions = false;
      return [...templateFilterAnswers.value, ...templateFilterQuestions.value]
        .map((q) => {
          if (isNil(q.answerValue) && shouldStopAddMoreQuestions) return;
          // Question to ask
          if (isNil(q.answerValue) && !shouldStopAddMoreQuestions) {
            shouldStopAddMoreQuestions = true;
            if (q.weight < filteredTemplates.value.length) {
              emit('on-filter-template-question-presented', q);
              return q;
            }
            return;
          }
          // Question that already answered and need to be showed
          return q;
        })
        .filter(Boolean);
    });

    const handleNewAnswer = (answer) => {
      templateFilterAnswers.value.push(answer);
      emit('on-filter-template-question-answered', answer);
    };

    const handleRemoveAnswer = (answer) => {
      const paramFieldToRemove = answer.paramField;
      const spliceIndex = templateFilterAnswers.value.findIndex((answer) => answer.paramField === paramFieldToRemove);
      const removedAnswers = templateFilterAnswers.value.splice(spliceIndex);
      emit('on-filter-template-question-answer-removed', removedAnswers);
    };

    const onChangeTemplate = ({ templateId = null } = {}) => {
      emit('on-change-template', templateId);
    };

    const onStructureParamToggle = ({ templateId = null } = {}) => {
      emit('on-template-structure-params-toggle', templateId);
    };

    const onLocaleChanged = (locale) => {
      emit('locale-changed', locale);
    };

    const onIsManualChanged = (isManual) => {
      emit('is-manual-changed', isManual);
    };

    const docType = computed(() => props.document.docType);
    const updateDocType = (e) => {
      emit('on-document-type-changed', e);
    };

    const onChangeDocumentNumber = (e) => {
      emit('on-document-number-changed', e);
    };

    const updateSupplierId = (e) => {
      emit('on-supplier-changed', e?.id);
    };

    const onChangeHasDeclaration = (e) => {
      const booleanResults = e === 'true';
      emit('on-has-declaration-changed', booleanResults);
    };

    const source = computed(() => {
      if (props.document?.source === 'email') {
        return $i18n.t('document.documentsOverview.classificationTasks.document.email');
      } else if (props.document?.source === 'folder') {
        return $i18n.t('document.documentsOverview.classificationTasks.document.folder');
      }

      return '';
    });

    const formattedCreatedAt = computed(() => formatDate(props.document?.createdAt));

    const hasDeclaration = computed(() => props.document?.hasDeclaration);

    const filePathUrl = computed(() => props.document?.filePathUrl);

    function extractFileNameFromPath(filePath = '') {
      const filenameWithExtension = filePath?.split('/').pop();
      return filenameWithExtension;
    }

    const fileName = computed(() => extractFileNameFromPath(props.document?.filePath));

    function formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString($i18n.locale, options.dateAndTime) : '';
    }

    const TYPES_WITH_HARD_CODED_DECLARATION = [
      DOCUMENT_TYPES.RECONCILIATION_STATEMENT,
      DOCUMENT_TYPES.AGED_DEBTORS_REPORT,
      DOCUMENT_TYPES.OTHER,
    ];

    const isHasDeclarationDisabled = computed(
      () => !props.document.type || TYPES_WITH_HARD_CODED_DECLARATION.includes(props.document.type)
    );

    const hasDeclarationColor = computed(() =>
      isHasDeclarationDisabled.value ? typography.typographyDisabled : typography.typographyPrimary
    );

    const showAlreadyExistDocument = ref(false);
    const isDocumentNumberAlreadyExists = computed(() => !!props.alreadyExistsDocumentWithDocumentNumber.id);

    return {
      templatesCard,
      onChangeTemplate,
      onStructureParamToggle,
      onLocaleChanged,
      onIsManualChanged,
      showAlreadyExistDocument,
      isDocumentNumberAlreadyExists,
      hasDeclarationColor,
      isHasDeclarationDisabled,
      formattedCreatedAt,
      hasDeclaration,
      filePathUrl,
      docType,
      onChangeHasDeclaration,
      onChangeDocumentNumber,
      updateDocType,
      updateSupplierId,
      fileName,
      source,
      handleScroll,
      templateCardRefs,
      selectedSupplierItem,
      presentedSuppliers,
      templateFilterQuestions,
      visibleTemplateQuestions,
      handleNewAnswer,
      handleRemoveAnswer,
      filteredTemplates,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
@import '@/modules/document/components/document-global.scss';

.wrapper {
  width: 100%;
}
.waiting-for-supplier-and-doc-type {
  background-color: #f3f3f4;
  color: $typography-secondary;
  width: 100%;
  height: calc($content-height - 95px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-medium;
}

.fontSize {
  font-size: $font-size-medium;
}

.duplicate-document-icon {
  margin: 8px;
}

.error-position {
  position: absolute;
  top: 28px;
}

.already-exist-error-link {
  cursor: pointer;
}

::v-deep .field-size {
  width: 100%;

  .el-input {
    height: 32px;
  }
  .el-input__inner {
    height: 32px;
  }

  .radio-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

::v-deep .error-border {
  .el-radio__inner {
    border-color: $error;
  }
  .el-input__inner {
    border-color: $error;
  }
}

.document-options-wrapper {
  background-color: #f3f3f4;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px;
}

.document-option-section-wrapper {
  align-items: center;
  display: flex;
  gap: 10px;
}

.templates-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.template-card-item {
  flex: 0 0 calc($content-height / 1.9);
  box-sizing: border-box;
}

.sticky-header {
  top: 0;
  position: sticky;
  z-index: 1000;
  background-color: $white;
}

.template-filter-wrapper {
  background-color: white;
  padding: 0.8rem 0 0.8rem 0;
}

.template-filter-container {
  display: flex;
  gap: 24px;
}

.supplier-dropdown {
  ::v-deep .dropdown-toggle {
    width: 100%;
    text-align: start;
    overflow-x: hidden;
  }
}

.supplier-dropdown-error {
  ::v-deep .dropdown-toggle {
    border-color: $error;
  }
}
</style>
