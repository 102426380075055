<template>
  <div>
    <div>
      <div>
        <div class="placeholder bg-secondary options-panel"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.options-panel {
  width: 100%;
  height: 60px;
}
</style>
